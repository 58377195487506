import React, {createContext, lazy, Suspense, useEffect, useState} from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import AnnouncementBanner from './components/AnnouncementBanner'
import Footer from './components/Footer'
import Navigation from './components/Navigation'
import useMenus from './hooks/useMenus'
import useOptions from './hooks/useOptions'
import useNeighborhoods from './hooks/useNeighborhoods'
import usePages from './hooks/usePages'
import Loader from './components/Loader'
import Modal from './components/Modal'

const Home = lazy(() => import(/* webpackChunkName: "home" */ './pages/Home'));
const Residential = lazy(() => import(/* webpackChunkName: "residential" */ './pages/Residential'));
const Property = lazy(() => import(/* webpackChunkName: "property" */ './pages/Property'));
const ApartmentDetails = lazy(() => import(/* webpackChunkName: "apartmentDetails" */ './pages/ApartmentDetails'));
const CommercialDetails = lazy(() => import(/* webpackChunkName: "commercialDetails" */ './pages/CommercialDetails'));
const Neighborhoods = lazy(() => import(/* webpackChunkName: "neighborhoods" */ './pages/Neighborhoods'));
const NeighborhoodDetail = lazy(() => import(/* webpackChunkName: "neighborhoodDetails" */ './pages/NeighborhoodDetail'));
const ResidentialAvailability = lazy(() => import(/* webpackChunkName: "residentialAvailability" */ './pages/ResidentialAvailability'));
const CommercialAvailability = lazy(() => import(/* webpackChunkName: "commercialAvailability" */ './pages/CommercialAvailability'));
const About = lazy(() => import(/* webpackChunkName: "about" */ './pages/About'));
const Contact = lazy(() => import(/* webpackChunkName: "contact" */ './pages/Contact'));
const NotFoundPage = lazy(() => import(/* webpackChunkName: "contact" */ './pages/NotFound'));

export const AppContext = createContext({
  menus: {},
  options: {},
  neighborhoods: [],
  pages: {},
  medias: {},
  addMedia: (id, result) => {},
})

const App = () => {
  const menus = useMenus()
  const options = useOptions()
  const neighborhoods = useNeighborhoods()
  const pages = usePages()
  const [medias, setMedias] = useState({})
  const [showModalEmail, setShowModalEmail] = useState(false)

  useEffect(() => {
    // check the time to open modal
    setTimeout(() => setShowModalEmail(true), 30000)
  }, [])

  const onCloseModal = () => setShowModalEmail(false)

  function addMedia(id, result) {
    if (id && result) {
      setMedias({...medias, [id]: result})
    }
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{ menus, options, neighborhoods, pages, medias, addMedia }}>
        <div>
          <div className='main-header'>
            <AnnouncementBanner />
            <Navigation menus={menus} />
          </div>
          <Suspense fallback={<Loader />}>
            <Switch>
              <Route path='/' component={Home} exact />
              <Route path='/residential' component={Residential} exact />
              <Route path='/properties/:slug' component={Property} exact />
              <Route path='/apartments/:slug' component={ApartmentDetails} exact />
              <Route path='/commercial_details' component={CommercialDetails} exact />
              <Route path='/neighborhoods' component={Neighborhoods} exact />
              <Route
                path='/neighborhoods/:slug'
                component={NeighborhoodDetail}
                exact
              />
              <Route
                path='/residential-availability'
                component={ResidentialAvailability}
                exact
              />
              <Route
                path='/commercial_availability'
                component={CommercialAvailability}
                exact
              />
              <Route path='/about' component={About} exact />
              <Route path='/contact' component={Contact} exact />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Suspense>
        </div>
        {showModalEmail && <Modal onClose={onCloseModal} />}
        <Footer />
      </AppContext.Provider>
    </BrowserRouter>
  )
}

export default App
