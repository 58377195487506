import React, { useState, useEffect, useContext } from 'react'
import Hamburger from 'react-hamburger-menu'
import { Link } from './Link'
import { useCookie } from '../hooks/useCookie'
import { AppContext } from '../App'
import Logo from '../assets/images/Logo.svg'

const Navigation = () => {
  const context = useContext(AppContext)

  const [menuClicked, setMenuClicked] = useState(null)

  useCookie('menus', menuClicked)

  const menu = context?.menus['centerMenu'] || []
  const rightButtons = context?.menus['rightButtons'] || []
  const mobileMenu = context?.menus['mobileHeader'] || []
  const options = context?.options

  const [siteLogo, setSiteLogo] = useState(Logo)
  const [navbarOpen, setNavbarOpen] = useState(false)

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
  }

  useEffect(() => {
    if (options?.site_logo?.url) {
      setSiteLogo(options?.site_logo?.url)
    }
  }, [options])

  return (
    <nav className="navigation">
      <div className="hamburgerMenu">
        <Hamburger
          isOpen={navbarOpen}
          menuClicked={handleToggle}
          width={30}
          height={18}
          strokeWidth={4}
          rotate={0}
          color="black"
          borderRadius={0}
          animationDuration={0.5}
        />
      </div>
      <Link href="/" className="logoLink">
        <img alt="GoNoFee Logo" className="logo" src={siteLogo} />
      </Link>
      <div className="menuItems">
        {menu.map((link) => (
          <Link key={link.title} target={link.target} href={link.url}>
            <h4 className="menuItem">{link.title}</h4>
          </Link>
        ))}
      </div>
      <div className="rightButtons">
        {rightButtons.map((link) => (
          <Link
            key={link.title}
            target={link.target}
            href={link.url}
            className="no-underline"
          >
            <div
              onClick={() => setMenuClicked(link.title)}
              className={link.classes + ' btn button'}
            >
              {link.title}
            </div>
          </Link>
        ))}
      </div>
      <div className={`hamburgerMenuItems ${navbarOpen ? ' showMenu' : ''}`}>
        <ul>
          {console.log('menu', mobileMenu)}
          {mobileMenu.map((link) => (
            <li key={link.title} onClick={() => setNavbarOpen(false)}>
              {link.title === 'Apply' ? (
                <a href={link.url} target="_blank">
                  {link.title}
                </a>
              ) : (
                <Link target={link.target} href={link.url}>
                  {link.title}
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default Navigation
